import { default as _91slug_93U8zJaWati2Meta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/artistas/[slug].vue?macro=true";
import { default as indexaeeA2bjmjuMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/artistas/index.vue?macro=true";
import { default as aviso_45de_45privacidadBnCFMNPRjwMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/aviso-de-privacidad.vue?macro=true";
import { default as indexxkVnVcxnguMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/exhibiciones/index.vue?macro=true";
import { default as google_45auth_45callbacklhsMa4D9vVMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/google-auth-callback.vue?macro=true";
import { default as indexO6wrQEqmjbMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/index.vue?macro=true";
import { default as loginCfFBRU4lKhMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/login.vue?macro=true";
import { default as nosotros9UtEB7FfVgMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/nosotros.vue?macro=true";
import { default as indexyaJWg8YzzoMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/perfil/index.vue?macro=true";
import { default as indexG6DeE7nie1Meta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/proyectos-especiales/index.vue?macro=true";
import { default as recuperarWMCPYyRslaMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/recuperar.vue?macro=true";
import { default as registrovOqJb6XqELMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/registro.vue?macro=true";
import { default as restablecerNgC0yD4nzUMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/restablecer.vue?macro=true";
import { default as searchdfL8Ko1HzvMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/search.vue?macro=true";
import { default as indexcSATr797omMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/series/index.vue?macro=true";
import { default as verificacionIRM8rDTEWZMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/verificacion.vue?macro=true";
import { default as PostDetailsHf9SqIvMR0Meta } from "~/components/views/PostDetails.vue?macro=true";
export default [
  {
    name: _91slug_93U8zJaWati2Meta?.name ?? "artistas-slug",
    path: _91slug_93U8zJaWati2Meta?.path ?? "/artistas/:slug()",
    meta: _91slug_93U8zJaWati2Meta || {},
    alias: _91slug_93U8zJaWati2Meta?.alias || [],
    redirect: _91slug_93U8zJaWati2Meta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/artistas/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexaeeA2bjmjuMeta?.name ?? "artistas",
    path: indexaeeA2bjmjuMeta?.path ?? "/artistas",
    meta: indexaeeA2bjmjuMeta || {},
    alias: indexaeeA2bjmjuMeta?.alias || [],
    redirect: indexaeeA2bjmjuMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/artistas/index.vue").then(m => m.default || m)
  },
  {
    name: aviso_45de_45privacidadBnCFMNPRjwMeta?.name ?? "aviso-de-privacidad",
    path: aviso_45de_45privacidadBnCFMNPRjwMeta?.path ?? "/aviso-de-privacidad",
    meta: aviso_45de_45privacidadBnCFMNPRjwMeta || {},
    alias: aviso_45de_45privacidadBnCFMNPRjwMeta?.alias || [],
    redirect: aviso_45de_45privacidadBnCFMNPRjwMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/aviso-de-privacidad.vue").then(m => m.default || m)
  },
  {
    name: indexxkVnVcxnguMeta?.name ?? "exhibiciones",
    path: indexxkVnVcxnguMeta?.path ?? "/exhibiciones",
    meta: indexxkVnVcxnguMeta || {},
    alias: indexxkVnVcxnguMeta?.alias || [],
    redirect: indexxkVnVcxnguMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/exhibiciones/index.vue").then(m => m.default || m)
  },
  {
    name: google_45auth_45callbacklhsMa4D9vVMeta?.name ?? "google-auth-callback",
    path: google_45auth_45callbacklhsMa4D9vVMeta?.path ?? "/google-auth-callback",
    meta: google_45auth_45callbacklhsMa4D9vVMeta || {},
    alias: google_45auth_45callbacklhsMa4D9vVMeta?.alias || [],
    redirect: google_45auth_45callbacklhsMa4D9vVMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/google-auth-callback.vue").then(m => m.default || m)
  },
  {
    name: indexO6wrQEqmjbMeta?.name ?? "index",
    path: indexO6wrQEqmjbMeta?.path ?? "/",
    meta: indexO6wrQEqmjbMeta || {},
    alias: indexO6wrQEqmjbMeta?.alias || [],
    redirect: indexO6wrQEqmjbMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginCfFBRU4lKhMeta?.name ?? "login",
    path: loginCfFBRU4lKhMeta?.path ?? "/login",
    meta: loginCfFBRU4lKhMeta || {},
    alias: loginCfFBRU4lKhMeta?.alias || [],
    redirect: loginCfFBRU4lKhMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: nosotros9UtEB7FfVgMeta?.name ?? "nosotros",
    path: nosotros9UtEB7FfVgMeta?.path ?? "/nosotros",
    meta: nosotros9UtEB7FfVgMeta || {},
    alias: nosotros9UtEB7FfVgMeta?.alias || [],
    redirect: nosotros9UtEB7FfVgMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/nosotros.vue").then(m => m.default || m)
  },
  {
    name: indexyaJWg8YzzoMeta?.name ?? "perfil",
    path: indexyaJWg8YzzoMeta?.path ?? "/perfil",
    meta: indexyaJWg8YzzoMeta || {},
    alias: indexyaJWg8YzzoMeta?.alias || [],
    redirect: indexyaJWg8YzzoMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/perfil/index.vue").then(m => m.default || m)
  },
  {
    name: indexG6DeE7nie1Meta?.name ?? "proyectos-especiales",
    path: indexG6DeE7nie1Meta?.path ?? "/proyectos-especiales",
    meta: indexG6DeE7nie1Meta || {},
    alias: indexG6DeE7nie1Meta?.alias || [],
    redirect: indexG6DeE7nie1Meta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/proyectos-especiales/index.vue").then(m => m.default || m)
  },
  {
    name: recuperarWMCPYyRslaMeta?.name ?? "recuperar",
    path: recuperarWMCPYyRslaMeta?.path ?? "/recuperar",
    meta: recuperarWMCPYyRslaMeta || {},
    alias: recuperarWMCPYyRslaMeta?.alias || [],
    redirect: recuperarWMCPYyRslaMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/recuperar.vue").then(m => m.default || m)
  },
  {
    name: registrovOqJb6XqELMeta?.name ?? "registro",
    path: registrovOqJb6XqELMeta?.path ?? "/registro",
    meta: registrovOqJb6XqELMeta || {},
    alias: registrovOqJb6XqELMeta?.alias || [],
    redirect: registrovOqJb6XqELMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/registro.vue").then(m => m.default || m)
  },
  {
    name: restablecerNgC0yD4nzUMeta?.name ?? "restablecer",
    path: restablecerNgC0yD4nzUMeta?.path ?? "/restablecer",
    meta: restablecerNgC0yD4nzUMeta || {},
    alias: restablecerNgC0yD4nzUMeta?.alias || [],
    redirect: restablecerNgC0yD4nzUMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/restablecer.vue").then(m => m.default || m)
  },
  {
    name: searchdfL8Ko1HzvMeta?.name ?? "search",
    path: searchdfL8Ko1HzvMeta?.path ?? "/search",
    meta: searchdfL8Ko1HzvMeta || {},
    alias: searchdfL8Ko1HzvMeta?.alias || [],
    redirect: searchdfL8Ko1HzvMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: indexcSATr797omMeta?.name ?? "series",
    path: indexcSATr797omMeta?.path ?? "/series",
    meta: indexcSATr797omMeta || {},
    alias: indexcSATr797omMeta?.alias || [],
    redirect: indexcSATr797omMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/series/index.vue").then(m => m.default || m)
  },
  {
    name: verificacionIRM8rDTEWZMeta?.name ?? "verificacion",
    path: verificacionIRM8rDTEWZMeta?.path ?? "/verificacion",
    meta: verificacionIRM8rDTEWZMeta || {},
    alias: verificacionIRM8rDTEWZMeta?.alias || [],
    redirect: verificacionIRM8rDTEWZMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240408064916771/src/pages/verificacion.vue").then(m => m.default || m)
  },
  {
    name: PostDetailsHf9SqIvMR0Meta?.name ?? "series-slug",
    path: PostDetailsHf9SqIvMR0Meta?.path ?? "/series/:slug",
    meta: PostDetailsHf9SqIvMR0Meta || {},
    alias: PostDetailsHf9SqIvMR0Meta?.alias || [],
    redirect: PostDetailsHf9SqIvMR0Meta?.redirect || undefined,
    component: () => import("~/components/views/PostDetails.vue").then(m => m.default || m)
  },
  {
    name: PostDetailsHf9SqIvMR0Meta?.name ?? "exhibiciones-slug",
    path: PostDetailsHf9SqIvMR0Meta?.path ?? "/exhibiciones/:slug",
    meta: PostDetailsHf9SqIvMR0Meta || {},
    alias: PostDetailsHf9SqIvMR0Meta?.alias || [],
    redirect: PostDetailsHf9SqIvMR0Meta?.redirect || undefined,
    component: () => import("~/components/views/PostDetails.vue").then(m => m.default || m)
  },
  {
    name: PostDetailsHf9SqIvMR0Meta?.name ?? "proyectos-especiales-slug",
    path: PostDetailsHf9SqIvMR0Meta?.path ?? "/proyectos-especiales/:slug",
    meta: PostDetailsHf9SqIvMR0Meta || {},
    alias: PostDetailsHf9SqIvMR0Meta?.alias || [],
    redirect: PostDetailsHf9SqIvMR0Meta?.redirect || undefined,
    component: () => import("~/components/views/PostDetails.vue").then(m => m.default || m)
  }
]